@import '~Style/variable';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  &.withFooter {
    padding-bottom: $global-footer-height;
    margin-bottom: -$global-footer-height; } }
