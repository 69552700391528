@import '~Style/variable';

.dialog {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  background-color: $primary-white;
  text-align: left;
  vertical-align: inherit;

  @media screen and (min-width: 601px) {
    max-width: 500px; } }

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .title {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase; }

  button {
    border: none;
    background: transparent;
    color: $gray-dark;
    font-weight: 700;
    display: flex;
    align-items: center;

    .text {
      text-transform: uppercase;
      font-size: .875rem; }

    span {
      font-size: 1.5rem; } } }




