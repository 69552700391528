$max-content-width: 1100px;

$padding-base-horizontal: 15px;
$padding-xl-horizontal: $padding-base-horizontal * 2;
$padding-small-horizontal: 10px;

$margin-base-horizontal: 15px;
$margin-small-horizontal: 10px;

$global-header-height: 50px;
$global-footer-height: 38px;

$font-size-md: 1.2em;

//== Primary Colours
//
// Grey Colours
$primary-black: #1a1a1a;
$primary-nickel: #333;
$primary-darkgray: #767676;
$primary-gray: #bfbfbf;
$primary-silver: #e1e1e1;
$primary-lightgray: #eee;
$primary-white: #fff;
$primary-blue: #0286cd;

//== Accent Colours
//
// Blue Colours
$blue-100: #0d83ff;
$blue-80: #3396ff;
$blue-60: #66b0ff;
$blue-20: #cce5ff;

//== Notification Colours
//
// Red Colours
$red-100: #ff270d;
$red-80: #ff4933;
$red-60: #ff7666;
$red-20: #ffd1cc;

// Yellow Colours
$yellow-100: #f2b400;
$yellow-80: #ebbc33;
$yellow-60: #f0cd66;
$yellow-20: #faeecc;

// Green Colours
$green-100: #65cc3d;
$green-80: #6ed446;
$green-60: #88e066;
$green-20: #d7f5cc;

//== Secondary Colours
//
// Orange Colours
$orange-100: #ff720d;
$orange-80: #ff8b39;
$orange-60: #f7a266;
$orange-20: #fce0cc;

// BrightYellow Colours
$bright-yellow-100: #edda00;
$bright-yellow-80: #f1e133;
$bright-yellow-60: #f4e966;
$bright-yellow-20: #fbf8cc;

// LightGreen Colours
$light-green-100: #b0d400;
$light-green-80: #bdd936;
$light-green-60: #cbe066;
$light-green-20: #eef5cc;

// LightBlue Colours
$light-blue-100: #14aecc;
$light-blue-80: #33bcd6;
$light-blue-60: #66cde0;
$light-blue-20: #cceef5;

// DarkBlue Colours
$dark-blue-100: #405fff;
$dark-blue-80: #516dff;
$dark-blue-60: #7d92ff;
$dark-blue-20: #d4dbff;

// Purple Colours
$purple-100: #9f40ff;
$purple-80: #a851ff;
$purple-60: #be7dff;
$purple-20: #e9d4ff;

// Pink Colours
$pink-100: #ff339c;
$pink-80: #ff47a6;
$pink-60: #ff75bc;
$pink-20: #ffd1e9;

$gray-base: $primary-black;
$gray-darker: $primary-nickel;
$gray-dark: $primary-darkgray;
$gray: $primary-gray;
$gray-light: $primary-silver;
$gray-lighter: $primary-white;
