@import '~Style/variable';

.container {
  background-color: $blue-100;
  border: none;
  padding: 0 1rem;
  height: 2.5rem;
  text-transform: uppercase;
  color: $primary-white;
  font-size: 0.875rem;
  font-weight: 700;

  &:hover {
    box-shadow: 0 1px 0.2em 0 rgba(26,26,26,.3); } }


.tertiary {
  background: transparent;
  color: $primary-darkgray;

  &:hover {
    color: $blue-100; } }

.secondary {
  background-color: $primary-gray;
  color: $blue-100; }

.disabled {
  opacity: 0.6;
  cursor: default;

  &:hover {
    box-shadow: none; } }
