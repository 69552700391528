@import '~Style/variable';

.container {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  input {
    display: none; } }

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary-darkgray;
  background-color: $primary-white;
  width: 1.5rem !important;
  height: 1.5rem !important;
  flex-shrink: 0;
  vertical-align: middle;
  transition: background-color 0.3s; }

.label {
  display: inline-block;
  vertical-align: middle;
  color: $primary-darkgray;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 1rem; }

.disabled {
  cursor: auto;

  .checkbox {
    border-color: $primary-gray; }

  .label {
    color: $primary-gray; } }

.checked {
  .checkbox {
    position: relative;
    background-color: $blue-100;
    border-color: $blue-100;
    padding: 0;
    color: $primary-white;
    font-size: 1.3rem; }

  .label {
    color: $blue-100; }

  &.disabled {
    .label {
      color: $primary-gray; }

    .checkbox {
      background-color: $primary-gray;
      border-color: $primary-gray; } } }
