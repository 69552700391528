@import '~Style/variable';
@import '~Style/device';

.container {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: $global-footer-height;
  background-color: $primary-black; }

.content {
  display: flex;
  padding: 0 $padding-base-horizontal;
  max-width: $max-content-width;
  width: 100%;
  color: $primary-white;
  list-style: none;
  margin: 0;

  @media only screen and (min-width: $desktop-min-width) {
    justify-content: flex-end; } }

.item {
  padding: 0 0.3rem;
  flex-shrink: 0;
  width: 33%;
  line-height: 0.9rem;

  @media only screen and (min-width: $desktop-min-width) {
    width: auto;
    padding: 0 0.8rem; }

  &:first-of-type {
    padding-left: 0; }

  &:last-of-type {
    padding-right: 0; } }

.link {
  border: none;
  background: transparent;
  font-size: 0.8rem;
  color: $primary-white;
  text-decoration: none;
  font-weight: 400;

  @media only screen and (min-width: $desktop-min-width) {
    font-size: 1rem; }

  &:hover {
    color: $primary-white;
    text-decoration: underline; }

  &:visited {
    color: $primary-white; } }
