@import '~Style/variable';

.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);

  &.fixed {
    position: fixed;
    width: 100vw;
    height: 100vh; }

  div {
    position: absolute;
    top: 50%;
    left: 10%;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: 0;
    background-color: $primary-white;
    animation: loaderMove 3.5s ease-in-out infinite;

    &:nth-of-type(1) {
      transform: translate(-200%, -50%);
      animation-delay: 0.9s; }

    &:nth-of-type(2) {
      transform: translate(-50%, -50%);
      animation-delay: 0.6s; }

    &:nth-of-type(3) {
      transform: translate(100%, -50%);
      animation-delay: 0.3s; } } }

@keyframes loaderMove {
  0% {
    opacity: 0;
    margin: 0;
    left: 10%; }

  40% {
    left: 50%;
    opacity: 1; }

  60% {
    left: 50%;
    opacity: 1; }

  100% {
    opacity: 0;
    margin: 0;
    left: 90%; } }
