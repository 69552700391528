@import '~Style/variable';

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; }

.label {
  flex-shrink: 0;
  font-size: 1rem;
  color: $primary-nickel;
  margin-right: 3rem;
  line-height: 2.3; }

.input_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  border: 1px solid $primary-silver;
  background-color: $primary-white;
  width: 100%;
  height: 2.3rem;

  &:hover {
    box-shadow: 0 1px 0.2em 0 rgba(26,26,26,.3); } }

.input {
  flex-grow: 1;
  padding: 0 0.5rem;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  color: $primary-nickel;
  border-radius: 0;

  &::placeholder {
    font-style: italic; } }

.postfix {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 2.3rem;
  padding: 0 0.5rem;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: $primary-silver;
  background-color: $primary-lightgray; }

.horizontal {
  flex-direction: row;
  align-items: center; }

.disabled {
  .input {
    background-color: $primary-lightgray;
    color: $primary-gray;
    font-style: italic;
    cursor: default; }

  .input_container:hover {
      box-shadow: none; } }

.required {
  color: $red-100; }
