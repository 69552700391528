@import '~Style/variable';

.container {
  padding: 15px 0;
  border-bottom: 1px solid $primary-lightgray;

  &:last-of-type {
    border-bottom: none; } }

.header {
  font-size: 1.1rem;
  font-weight: bold; }

.content {
  ul {
    padding-left: 20px;
    font-size: 0.85rem; } }
