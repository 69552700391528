@import '~Style/variable';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between; }

.label {
  flex-shrink: 0;
  font-size: 1rem;
  color: $primary-nickel;
  margin-right: 3rem;
  line-height: 2.3; }

.content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $primary-silver;
  background-color: $primary-white;
  min-height: 2.3rem;
  width: 100%;
  padding: 0 0.5rem;

  &:hover {
    box-shadow: 0 1px 0.2em 0 rgba(26,26,26,.3); } }

.focus {
  border-color: $blue-80; }

.input_read {
  border: none;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2.1rem;
  background: transparent;
  font-size: 1rem;
  color: $primary-nickel;
  padding: 0;

  &:active, &:focus {
    outline: none; } }

.text {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  font-size: 1rem;
  height: 1.3rem; }

.clear {
  margin-right: 0.5rem;

  &:hover {
    color: $red-100; } }

.input_container {
  display: flex;
  align-items: center;
  width: 100%; }

.input {
  font-size: 1rem;
  font-weight: 400;
  color: $primary-nickel;
  border: none;
  background: transparent;
  flex-grow: 1;
  height: 100%;
  min-width: 0;

  &:active, &:focus {
    outline: none; } }

.options {
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  max-height: 15rem;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid $primary-silver;
  background-color: $primary-white;
  overflow: auto;
  z-index: 2; }

.selected {
  background-color: $blue-20; }

.option {
  display: flex;
  align-items: center;
  transition: 0.5s opacity;

  &:hover {
    background-color: $primary-lightgray; }

  & button {
    padding: 0 0.8rem;
    border: none;
    width: 100%;
    min-height: 2rem;
    text-align: left;
    background: transparent;
    color: $primary-nickel;
    font-size: 1rem;
    word-break: break-word;
    word-wrap: break-word; } }

.spinner {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem; }

.loading {
  .option, .emptyOptions {
    opacity: 0.5; } }

.placeholder {
  .text {
    font-style: italic;
    color: $primary-darkgray; } }

.tag {
  display: flex;
  justify-content: space-between;
  min-height: 1.5rem;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  border: 1px solid $blue-100;
  margin: 2px 7px 2px 0;
  font-size: 0.85rem;
  color: $blue-100;
  word-break: break-all;
  flex-shrink: 0;

  button {
    border: none;
    background: transparent;
    margin-left: 5px;
    padding: 0;
    font-size: 1.1rem;

    &:hover {
      color: $red-100; } } }

.multi {
  .input_container {
    flex-wrap: wrap;
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    width: calc(100% + 2px);
    padding: 0 0.5rem;
    border: 1px solid $blue-100;
    min-height: calc(100% + 2px);
    z-index: 1;
    background-color: $primary-white; }

  .input {
    height: auto; } }

.disabled {
  .content {
    background-color: $primary-lightgray;
    cursor: default;

    &:hover {
      box-shadow: none; }

    .text {
      color: $primary-gray;
      font-style: italic; } } }

.emptyOptions {
  padding: 5px 10px; }

.required {
  color: $red-100; }
