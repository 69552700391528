@import '~Style/fonts';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~common-user-interface/dist/ds-icon.css';
@import '~Style/brick';
@import '~Style/placeholder';
@import '~Style/variable';

* {
  box-sizing: border-box; }

html {
  font-size: 16px; }

html, body, #root {
  height: 100%; }

#root {
  overflow-x: hidden; }

body.ds {
  margin: 0;
  padding: 0;
  font-family: adihaus;
  font-size: inherit;
  color: $primary-nickel; }

h1 {
  font-size: 4.5rem; }

h2 {
  font-size: 3rem; }

h3 {
  font-size: 2.5rem; }

h4 {
  font-size: 2rem; }

h5 {
  font-size: 1.5rem; }

h6 {
  font-size: 1.25rem; }

small {
  font-size: 0.875rem; }

.layout {
  height: 100%; }

.main-content {
  @extend %flex;
  @extend %direction-column;
  padding-top: $global-header-height; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }
