@font-face {
  font-family: 'adihaus-din';
  src: url('~Style/fonts/adihaus-din-regular/adihaus-din-regular.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-din-regular/adihaus-din-regular.svg?dxscim#adihaus-din-regular') format('svg');
  font-weight: 100;
  font-style: normal;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus-din';
  src: url('~Style/fonts/adihaus-din-regular-italic/adihaus-din-regular-italic.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-din-regular-italic/adihaus-din-regular-italic.svg?dxscim#adihaus-din-regular-italic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus-din';
  src: url('~Style/fonts/adihaus-din-medium/adihaus-din-medium.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-din-medium/adihaus-din-medium.svg?dxscim#adihaus-din-medium') format('svg');
  font-weight: 400;
  font-style: normal;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus-din';
  src: url('~Style/fonts/adihaus-din-medium-italic/adihaus-din-medium-italic.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-din-medium-italic/adihaus-din-medium-italic.svg?dxscim#adihaus-din-medium-italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus-din';
  src: url('~Style/fonts/adihaus-din-bold/adihaus-din-bold.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-din-bold/adihaus-din-bold.svg?dxscim#adihaus-din-bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus-din';
  src: url('~Style/fonts/adihaus-din-bold-italic/adihaus-din-bold-italic.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-din-bold-italic/adihaus-din-bold-italic.svg?dxscim#adihaus-din-bold-italic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adineue-pro-tt';
  src: url('~Style/fonts/adineue-pro-tt-light/adineue-pro-tt-light.woff?dxscim') format('woff'), url('~Style/fonts/adineue-pro-tt-light/adineue-pro-tt-light.svg?dxscim#adineue-pro-tt-light') format('svg');
  font-weight: 100;
  font-style: normal;
  font-kerning: normal;
  font-synthesis: none;
  font-variant-caps: normal; }


@font-face {
  font-family: 'adineue-pro-tt';
  src: url('~Style/fonts/adineue-pro-tt-black/adineue-pro-tt-black.woff?dxscim') format('woff'), url('~Style/fonts/adineue-pro-tt-black/adineue-pro-tt-black.svg?dxscim#adineue-pro-tt-black') format('svg');
  font-weight: 700;
  font-style: normal;
  font-kerning: normal;
  font-synthesis: none;
  font-variant-caps: normal; }


@font-face {
  font-family: 'adihaus';
  src: url('~Style/fonts/adihaus-regular/adihaus-regular.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-regular/adihaus-regular.svg?dxscim#adihaus-regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus';
  src: url('~Style/fonts/adihaus-regular-italic/adihaus-regular-italic.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-regular-italic/adihaus-regular-italic.svg?dxscim#adihaus-regular-italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus';
  src: url('~Style/fonts/adihaus-bold/adihaus-bold.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-bold/adihaus-bold.svg?dxscim#adihaus-bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-kerning: normal;
  font-synthesis: none; }


@font-face {
  font-family: 'adihaus';
  src: url('~Style/fonts/adihaus-bold-italic/adihaus-bold-italic.woff?dxscim') format('woff'), url('~Style/fonts/adihaus-bold-italic/adihaus-bold-italic.svg?dxscim#adihaus-bold-italic') format('svg');
  font-weight: 700;
  font-style: italic;
  font-kerning: normal;
  font-synthesis: none; }
