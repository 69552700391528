@import '~Style/variable';

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  text-align: center;
  vertical-align: middle;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: $margin-base-horizontal;

  &:after {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: inherit; } }
