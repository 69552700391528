@import '~Style/variable';
@import '~Style/device';

.container {
  width: 100%;
  max-width: 1000px;
  padding: $padding-base-horizontal;
  overflow: auto;

  @media only screen and (min-width: $desktop-min-width) {
    padding: $padding-xl-horizontal; } }
