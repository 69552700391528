@import 'placeholder';
@import 'variable';

.sub-title {
 font-size: $font-size-md; }

// Full height.
.full-height {
  height: 100%; }

.full-width {
  width: 100%; }

.full-size {
  width: 100%;
  height: 100%; }

// Break Words
.break-words {
  word-break: break-all; }

// Stretch.
.stretch {
  @extend %flex-stretch; }

// Align center.
.center {
  @extend %flex;
  @extend %align-center;
  @extend %justify-center; }

.upper-case {
  @extend %uppercase; }

.light-label {
  color: #fff; }

.dark-bg {
  background-color: #444; }

.weight-lighter {
  font-weight: lighter; }

.weight-normal {
  font-weight: normal; }

.weight-bold {
  font-weight: bold; }

// Padding.
.padding-xl-left {
  padding-left: $padding-base-horizontal * 2; }

// Padding.
.padding-xl-right {
  padding-right: $padding-base-horizontal * 2; }

// Padding.
.padding-xl-top {
  padding-top: $padding-base-horizontal * 2; }

// Padding.
.padding-xl-bottom {
  padding-bottom: $padding-base-horizontal * 2; }

// Padding.
.padding-md-top {
  padding-top: $padding-base-horizontal; }

// Padding.
.padding-md-bottom {
  padding-bottom: $padding-base-horizontal; }

// Padding.
.padding-md-left {
  padding-left: $padding-base-horizontal; }

// Padding.
.padding-md-right {
  padding-right: $padding-base-horizontal; }

// Vertical medium padding.
.padding-md-v {
  @extend .padding-md-top;
  @extend .padding-md-bottom; }

// Horizontal medium padding.
.padding-md-h {
  @extend .padding-md-left;
  @extend .padding-md-right; }

// Medium padding around.
.padding-md {
  @extend .padding-md-top;
  @extend .padding-md-bottom;
  @extend .padding-md-left;
  @extend .padding-md-right; }

// Padding.
.padding-sm-top {
  padding-top: $padding-base-horizontal / 2; }

// Padding.
.padding-sm-bottom {
  padding-bottom: $padding-base-horizontal / 2; }

// Padding.
.padding-sm-left {
  padding-left: $padding-base-horizontal / 2; }

// Padding.
.padding-sm-right {
  padding-right: $padding-base-horizontal / 2; }

// Vertical small padding.
.padding-sm-v {
  @extend .padding-sm-top;
  @extend .padding-sm-bottom; }

// Horizontal small padding.
.padding-sm-h {
  @extend .padding-sm-left;
  @extend .padding-sm-right; }

// Small padding around.
.padding-sm {
  @extend .padding-sm-top;
  @extend .padding-sm-bottom;
  @extend .padding-sm-left;
  @extend .padding-sm-right; }

// Stretch.
.stretch {
  @extend %flex-stretch; }

// Stretch with absolute.
.absolute {
  @extend %absolute; }

// Fixed element position.
.fixed {
  @extend %fixed; }

// Relative element position.
.relative {
  @extend %relative; }

// Stretch with absolute.
.radius-circle {
  @extend %radius-circle; }

// Flex
.flex {
  @extend %flex; }

// Grow relatively to the rest of the flexible items
// inside the same container.
.flex-grow {
  @extend %flex-grow;
  @extend %flex-shrink;
  flex-basis: 100%; }

// Grow relatively to the rest of the flexible items
// inside the same container.
.flex-grow-0-5 {
  flex-grow: 0.5 !important; }

// Grow relatively to the rest of the flexible items
// inside the same container.
.flex-grow-0-7 {
  flex-grow: 0.7 !important; }

// Restrict grow.
.flex-no-grow {
  @extend %flex-no-grow;
  @extend %flex-shrink;
  @extend %flex-basis-auto; }

// Shrink relatively to the rest of the flexible items
// inside the same container.
.flex-shrink {
  @extend %flex-shrink; }

// Restrict shrink.
.flex-no-shrink {
  @extend %flex-no-shrink;
  @extend %flex-grow;
  @extend %flex-basis-auto; }

// Equal initial length of a flexible item.
.flex-no-basis {
  @extend %flex-no-basis; }

// Allow flex to break item on few rows.
.flex-wrap {
  @extend %flex-wrap; }

// Deny flex to break item on few rows.
.flex-no-wrap {
  @extend %flex-no-wrap; }

.flex-auto {
  @extend %flex-grow;
  @extend %flex-shrink;
  @extend %flex-basis-auto; }

.flex-none {
  @extend %flex-no-shrink;
  @extend %flex-no-grow;
  @extend %flex-basis-auto; }

.flex-initial {
  @extend %flex-shrink;
  @extend %flex-no-grow;
  @extend %flex-basis-auto; }

// flex width in increments of 5% (5% - 100%)
@for $i from 1 through 20 {
  $i: $i * 5;
  .flex-#{$i} {
    flex: #{$i}\%; } }

.flex-0 {
  flex: 1 1 0; }

.flex-33 {
  flex: 33.33333%; }

.flex-66 {
  flex: 66.66666%; }

// Aligns the flexible container's items to center when the items do
// not use all available space on the main-axis
.justify-center {
  @extend %justify-center; }

// Aligns the flexible container's items to start when the items do
// not use all available space on the main-axis.
.justify-start {
  @extend %justify-start; }

// Aligns the flexible container's items to end when the items do
// not use all available space on the main-axis.
.justify-end {
  @extend %justify-end; }

// Aligns the flexible container's items to have space between when the items do
// not use all available space on the main-axis.
.justify-space-between {
  @extend %justify-space-between; }

// Default start alignment for items
// inside the flexible container.
.align-start {
  @extend %align-start; }

// Default center alignment for items
// inside the flexible container.
.align-center {
  @extend %align-center; }

// Col direction of the flexible items.
.direction-column {
  @extend %direction-column; }

.underlined {
  border-bottom: 1px solid $gray-light; }
