
/**************************
 ********** FLEX **********
 **************************/

// Flex
%flex {
  display: flex; }

// Grow relatively to the rest of the flexible items
// inside the same container.
%flex-grow {
  flex-grow: 1; }

// Restrict grow.
%flex-no-grow {
  flex-grow: 0; }

// Shrink relatively to the rest of the flexible items
// inside the same container.
%flex-shrink {
  flex-shrink: 1; }

// Restrict shrink.
%flex-no-shrink {
  flex-shrink: 0; }

// The initial length is equal to the
// length of the flexible item.
%flex-basis-auto {
  flex-basis: auto; }

// Equal initial length of a flexible item.
%flex-no-basis {
  flex-basis: 0; }

// Allow flex to break item on few rows.
%flex-wrap {
  flex-wrap: wrap; }

// Deny flex to break item on few rows.
%flex-no-wrap {
  flex-wrap: nowrap; }

// Default center alignment for items
// inside the flexible container.
%align-center {
  align-items: center; }

// Default container start alignment for items
// inside the flexible container.
%align-start {
  align-items: flex-start; }

// Default container end alignment for items
// inside the flexible container.
%align-end {
  align-items: flex-end; }

// Default container end alignment for items
// inside the flexible container.
%align-baseline {
  align-items: baseline; }

// Aligns the flexible container's items to center when the items do
// not use all available space on the main-axis
%justify-center {
  justify-content: center; }

// Aligns the flexible container's items to start when the items do
// not use all available space on the main-axis.
%justify-start {
  justify-content: flex-start; }

// Aligns the flexible container's items to end when the items do
// not use all available space on the main-axis.
%justify-end {
  justify-content: flex-end; }

// Aligns the flexible container's items to have space between when the items do
// not use all available space on the main-axis.
%justify-space-between {
  justify-content: space-between; }

// Alignment for the selected item to center
// inside the flexible container.
%self-center {
  align-self: center; }

// Alignment for the selected item to end.
// inside the flexible container.
%self-end {
  align-self: flex-end; }

// Alignment for the selected item to start
// inside the flexible container.
%self-start {
  align-self: self-start; }

// Row direction of the flexible items.
%direction-row {
  flex-direction: row; }

// Row reverse direction of the flexible items.
%direction-row-reverse {
  flex-direction: row-reverse; }

// Col direction of the flexible items.
%direction-column {
  flex-direction: column; }

// Col reverse direction of the flexible items.
%direction-column-reverse {
  flex-direction: column-reverse; }

// Center flexible items vertically and horizontally
// inside the flexible container.
%flex-center {
  @extend %flex;
  @extend %align-center;
  @extend %justify-center; }

// Equal flexible items.
%flex-equal {
  @extend %flex-grow;
  @extend %flex-no-basis; }

// Stretch flexible items.
%flex-stretch {
  @extend %flex;
  @extend %flex-grow;
  @extend %direction-column; }


/**************************
 ****** ADDITIONAL ********
 **************************/

%uppercase {
  text-transform: uppercase; }

%absolute {
  position: absolute; }

%relative {
  position: relative; }

%fixed {
  position: fixed; }

%radius-circle {
  border-radius: 50%; }
