@import '~Style/variable';
@import '~Style/device';

.navbar {
  display: inline-block;
  padding: 0.5rem 0;
  height: 100%;
  min-width: 250px;
  max-width: 80vw;
  animation: open 0.3s ease-in-out;
  background-color: $primary-black;
  overflow: auto;

  @keyframes open {
    0% {
      transform: translate(-100%); }

    100% {
      transform: translate(0); } }

  &.closing {
    animation: close 0.3s ease-in-out forwards;

    @keyframes close {
      0% {
        transform: translate(0); }

      100% {
        transform: translate(-100%); } } } }

.list {
  list-style: none;
  margin: 0;
  padding: 0; }

.list_item {
  border-top: 1px solid $primary-darkgray;

  &:last-of-type {
    border-bottom: 1px solid $primary-darkgray; } }

.link {
  display: inline-block;
  width: 100%;
  height: 100%;
  color: $primary-white;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 400;
  border: none;
  padding: 0.75rem;
  background: transparent;
  text-align: left;

  &:active, &:visited {
    color: $primary-white; }

  &:hover {
    color: $primary-white;
    text-decoration: none;

    @media only screen and (min-width: $desktop-min-width) {
      text-decoration: underline; } } }

.linkDisabled {
  @extend .link;
  color: $primary-darkgray;
  &:hover {
    color: $primary-darkgray;
    text-decoration: none;
    border: none;
    cursor: default; } }
