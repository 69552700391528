@import '~Style/variable';
@import '~Style/placeholder';
@import '~Style/device';

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: $global-header-height;
  @extend %flex-no-shrink; }

.headerMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  padding: 0 $padding-xl-horizontal;
  background-color: $primary-black;
  color: $primary-white; }


.heading {
  margin: 0;
  text-align: center;
  font-size: 1.2rem;
  text-transform: uppercase;

  @media only screen and (min-width: $desktop-min-width) {
    font-size: 2rem; } }

.headerDesktop {
  background-color: $primary-black;
  font-size: calc(10px + 2vmin);
  color: $primary-white;
  flex: 0 0 auto;
  height: inherit;
  @extend %flex;
  @extend %align-center;
  @extend %justify-space-between; }

.menu {
  position: absolute;
  top: 50%;
  left: $padding-base-horizontal;
  transform: translate(0, -50%);
  border: none;
  background: transparent;
  padding: 0;
  color: $primary-white;
  font-size: 1.5rem;

  @media only screen and (min-width: $desktop-min-width) {
    font-size: 2rem; } }
