@import '../../../Style/variable';

.container {
  display: inline-block;
  flex-shrink: 0;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border-width: 0.3rem;
  border-style: solid;
  border-color: $primary-blue $primary-lightgray $primary-lightgray;
  animation: spin 1s linear infinite;
  opacity: 0.5; }

@keyframes spin {
  0% {
    transform: rotate(0); }

  50% {
    transform: rotate(180deg); }

  100% {
    transform: rotate(360deg); } }
