@import '~Style/variable';
@import '~Style/device';

.content {
  flex-shrink: 0;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  background-color: $primary-white;
  overflow: auto;

  h1, h2, h3 {
    text-transform: none;
    font-weight: bold;
    letter-spacing: 0; }

  h1 {
    font-size: 1.5rem;
    margin-top: 2.1rem;
    margin-bottom: 1.4rem; }

  h2 {
    font-size: 1.2rem;
    margin-top: 2.1rem;
    margin-bottom: 1.4rem; }

  p {
    font-size: 1rem;
    margin-bottom: 2rem; }

  ul, ol {
    font-size: 1rem;
    padding-left: 1.4rem; }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    word-break: break-all;

    &:hover {
      text-decoration: underline; } }

  td {
    padding: 10px; } }
