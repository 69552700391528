@import '~Style/variable';
@import '~Style/device';

.card {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 5rem;
  right: $padding-base-horizontal;
  left: $padding-base-horizontal;
  padding: 8px $padding-base-horizontal;
  background-color: rgba($primary-white, 0.9);
  border-width: 1px;
  border-style: solid;
  box-shadow: 0 9px 18px 0 rgba(26,26,26,.3);

  @media only screen and (min-width: $desktop-min-width) {
    right: 3rem;
    left: auto;
    width: 24rem; } }

.message {
  width: 100%;
  margin: 0;
  font-size: 1rem; }

.confirm, .cancel {
  border: none;
  background: transparent;
  text-decoration: underline;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0.8rem;

  &:hover {
    text-decoration: none; } }

.warning {
  color: rgba($yellow-100, 0.9);
  border-color: rgba($yellow-100,.9); }

.error {
  color: rgba($red-100,.9);
  border-color: rgba($red-100,.9); }
