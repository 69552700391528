@import '../../../Style/placeholder';

/*
 * Icon rules
 */
.iconRoot {
  @extend %flex;
  @extend %align-center;

  &.iconReverse {
    @extend %direction-row-reverse; }

  &.hint {
    .iconRoot {
      .ds-icon {
        transform: none; } } } }
